import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { authorize, signIn, signOut } from "@/services/auth.service";
import { clearAuthTokens, isLoggedIn, setAuthTokens } from "axios-jwt";
import { usePathname, useRouter } from "next/navigation";
import { errorToast, infoToast } from "@/lib/toast";
import { useMe } from "@/hooks/useMe";
import { Donors } from "@/types/models/Donors";

export interface ContextAuth {
  authenticated: boolean;
  loginRequest: (email: string) => Promise<void>;
  authorizeRequest: (code: string, email?: string) => Promise<void>;
  logout: () => Promise<void>;
  user: Donors | undefined;
}
const AuthContext = createContext<ContextAuth | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>();

  const { user } = useMe(authenticated);

  const loginRequest = useCallback(
    async (email: string) => {
      try {
        await signIn({ email });
        setEmail(email);
        router.push(`/signin/verify`);
      } catch (e) {
        errorToast(`Probleme de connexion...`);
      }
    },
    [router]
  );

  const checkAuth = useCallback(async () => {
    const loggedIn = await isLoggedIn();
    setAuthenticated(loggedIn);
    if (
      !loggedIn &&
      !pathname.includes("/signin") &&
      !pathname.includes("/signup")
    ) {
      infoToast("Votre session a expiré. Veuillez vous reconnecter.");
      router.push("/signin");
    }
  }, [pathname, router]);

  const authorizeRequest = useCallback(
    async (code: string, inputEmail?: string) => {
      try {
        const response = await authorize({
          mode: "otp",
          input: { email: inputEmail ?? email!, otp: code },
        });
        setAuthTokens({
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
        });
        checkAuth();
        router.push("/");
        infoToast("Bienvenue!");
      } catch (e) {
        errorToast(`Probleme de connexion...`);
      }
    },
    [checkAuth, email, router]
  );

  const logout = useCallback(async () => {
    try {
      await signOut();
    } catch (e) {
      errorToast(`Probleme de deconnexion...`);
    }
    clearAuthTokens();
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    checkAuth();
    const intervalId = setInterval(checkAuth, 5000);
    return () => clearInterval(intervalId);
  }, [checkAuth]);

  const context = useMemo(
    () => ({
      authenticated,
      loginRequest,
      authorizeRequest,
      logout,
      user,
    }),
    [authenticated, authorizeRequest, loginRequest, logout, user]
  );
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

function useAuth(): ContextAuth {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
