import { routes } from "@/services/donors.service";
import useSWR from "swr";
import { fetcherV2 } from "@/services/api";
import { Donors } from "@/types/models/Donors";

export function useMe(ready: boolean = true) {
  const { data, error, mutate } = useSWR(
    () => (ready ? [routes.me, {}] : undefined),
    fetcherV2<Donors>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
      errorRetryInterval: 1000,
    }
  );

  return {
    user: data,
    refresh: mutate,
    isLoading: !error && !data,
    isError: error,
  };
}
