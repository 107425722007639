const config: any = {
  dev: {
    auth: "http://localhost:3000/dev/auth",
    accounts: "http://localhost:3000/dev/accounts",
    payments: "http://localhost:3000/dev/payments",
  },
};
export const API = process.env.NEXT_PUBLIC_API_BASE;
export default config;
export const API_V2 = process.env.NEXT_PUBLIC_API_BASE_V2;
